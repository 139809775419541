import { useEffect, useState } from "react";
import { BASE_URL_ENV, PAGE_SIZE } from "./constants";
import getAxiosWithDefaultHeaders from "../config";

export const useFetchSnapshotFacilityTasks = (site_code, closed, type) => {
  const [tasks, setTasks] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const Axios = getAxiosWithDefaultHeaders();

  useEffect(() => {
    const fetchSnapshotFacilityTasks = async () => {
      setIsLoading(true);
      const searchParams = {
        page: currentPage,
        page_size: PAGE_SIZE,
        closed,
        location_code: site_code,
        // latch: false,
        query: searchText,
        type,
      };
      const searchParamsStr = new URLSearchParams(searchParams).toString();
      try {
        const result = await Axios('/api/tasks?' + searchParamsStr);
        result.data.entries = result.data.entries.sort((a1, a2) => new Date(a2.created_at) - new Date(a1.created_at));
        setTasks(result.data.entries);
        setTotalItems(result.data.total_entries);
      } catch (error) {
        setTasks([]);
        setTotalItems(0);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSnapshotFacilityTasks();
  }, [currentPage, site_code, searchText]);

  return [isLoading, tasks, currentPage, setCurrentPage, totalItems, setSearchText];
};
