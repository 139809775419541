import { useState } from "react";
import { ROUTER, ROUTER_KEYS, SUB_DOMAIN } from "../routers";
import { KEY_USER, KEY_SNAPSHOT_USER } from "./constants";

export const getStorageItem = (keyUser) => JSON.parse(localStorage.getItem(keyUser));
export const setStorageItem = (keyUser, value) => localStorage.setItem(keyUser, JSON.stringify(value));
export const localStorageClear = () => localStorage.clear();

export const useUser = () => {
  const [user, setUser] = useState(getStorageItem(KEY_USER));

  const saveUser = (userInfo) => {
    setStorageItem(KEY_USER, userInfo);
    setUser(userInfo);
  };
  return {
    user,
    setUser: saveUser,
  };
};

export const useSnapShotUser = () => {
  const [user, setUser] = useState(getStorageItem(KEY_SNAPSHOT_USER));

  const saveUser = (userInfo) => {
    setStorageItem(KEY_SNAPSHOT_USER, userInfo);
    setUser(userInfo);
  };
  return {
    user,
    setUser: saveUser,
  };
};

/**
 *
 * @param {*} subDomain String
 * @returns router which corresponding to given subDomain, default admin router
 */
export const getRouterBySubDomain = (subDomain) => {
  // search router key corresponding to subDomain
  const routerKey = Object.keys(SUB_DOMAIN).find((value) => subDomain === value);
  if (routerKey) return ROUTER[SUB_DOMAIN[routerKey]];

  return ROUTER[ROUTER_KEYS.ADMIN]; // default Router
};

export const getCmsLink = () => {
  return process.env.REACT_APP_ENV === "production"
    ? "https://spareboxstorage.com"
    : "https://staging.spareboxstorage.com";
};

export const getFacilityTitle = (facility_name, store_number) => {
  return store_number && facility_name ? `${facility_name} - ${store_number}` : facility_name;
}
