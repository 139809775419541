import moment from "moment";

export const BASE_URL_ENV = {
  production: "https://rafa.spareboxtech.com",
  staging: "https://staging-rafa.spareboxtech.com",
  development: "https://staging-rafa.spareboxtech.com",
};

export const TASK_CATEGORIES = [
  {
    title: "Property Tasks",
    category: "property",
    optionLabel: "Facility",
  },
  {
    title: "Unit Tasks",
    category: "unit",
    optionLabel: "Unit",
  },
];
export const WARNING_DAYS_OLD = 10;
export const PAGE_SIZE = 10;

export const ROLES_LABEL_COLORS = {
  customer_service: "#D50037",
  property_manager: "#000",
  regional_manager: "#021f82",
};

export const FIELD_STAFF_TITLES = {
  property_manager: 'Property Manager',
  maintenance_tech: 'Maintenance Tech',
  field_tech: 'Field Tech'
};
export const CUSTOMER_FACING_TITLES = {
  remote_manager: 'Remote Manager',
  customer_service: 'Customer Service'
};


export const MANAGEMENT_TITLES = {
  district_manager: 'District Manager',
  regional_manager: 'Regional Manager',
  area_manager: 'Area Manager',
}

export const getBaseUrl = () => BASE_URL_ENV[process.env.REACT_APP_ENV]

export const getBackgroundColor = (role) => {
  if (CUSTOMER_FACING_TITLES[role]) return '#D50037'
  if (FIELD_STAFF_TITLES[role]) return '#000'
  if (MANAGEMENT_TITLES[role]) return '#021f82'
  return '#000'
}

export const getShortRole = role => {
  const roleTitle = CUSTOMER_FACING_TITLES[role] || FIELD_STAFF_TITLES[role] || MANAGEMENT_TITLES[role]
  if (roleTitle) return String(roleTitle).split(' ').map(word => word[0]).join('')
}

export const getRoleName = (role) => {
  return CUSTOMER_FACING_TITLES[role] || FIELD_STAFF_TITLES[role] || MANAGEMENT_TITLES[role];
};

export const dateDiff = (oldDate, newDate = new Date()) => {
  return moment(newDate).diff(moment(oldDate), "days");
};

export const priorOptions = [
  { value: 'urgent', label: 'Urgent', label1: 'Urgent', color: '#d50037', style: { fontWeight: 'bold' } },
  { value: 'high', label: 'High', label1: 'High Priority', color: '#d50037' },
  { value: 'medium', label: 'Medium', label1: 'Medium Priority', color: '#000000' },
  { value: 'low', label: 'Low', label1: 'Low Priority', color: '#000000' },
]
