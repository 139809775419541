import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BreadCrumb } from "../../../components";
import SnapShotHeader from "../../../components/SnapShotHeader";
import { PATH } from "../../../routers";
import { getLocationList } from "../../../utilities/common";
import { titleComponents } from "../../SnapShotDetail/constants";
import { BASE_URL_ENV } from "../SnapshotFacilityTasks/constants";
import "./styles.module.css";
import getAxiosWithDefaultHeaders from "../config";
import { useSnapShotUser } from "../../../utilities/helper";
import moment from "moment";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

export default function SnapshotBrandsReportDetail(props) {
  const { user } = useSnapShotUser();
  const locationList = getLocationList();
  let {
    match: {
      params: { site_code, brands_report_id },
    },
  } = props;
  const location = locationList.find((location) => location.location_code === site_code);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const axios = getAxiosWithDefaultHeaders();
  const [note, setNote] = useState("");

  useEffect(() => {
    const fetchBrandsReport = async () => {
      setIsLoading(true);
      try {
        const config = {
          headers: {
            "X-User-Email": user.email,
            "X-User-Token": user.user_token,
          },
        };
        const response = await axios.get(
          `${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/brands_reports/${brands_report_id}`,
          config
        );
        setData(response.data);
        setNote(response.data?.notes);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchBrandsReport();
  }, []);

  const reportSubmitDate = data?.report_submit_at ? moment(data?.report_submit_at).format("MMM DD, YYYY") : "";
  const reportSubmitTime = data?.report_submit_at ? moment(data?.report_submit_at).format("LT") : "";
  const brand_standard_reports = data?.brand_standard_reports || [];

  return (
    <>
      <div className="bg-light">
        <Helmet>
          <title>Snapshot</title>
        </Helmet>
        <SnapShotHeader />
        <div>
          <div className="row justify-content-between pt-2">
            <div className="col-9 ml-2">
              <BreadCrumb title="Archive Brand Reports" path={PATH.SNAPSHOT_ARCHIVE_BRANDS_REPORT(site_code)} />
            </div>
          </div>
        </div>
        <div>
          <div className="row justify-content-between pt-2"></div>
        </div>
        <div className="col-10 mx-auto min-vh-100">
          <div className="row align-items-start justify-content-between">
            <div className="d-flex align-items-center">
              <p className={"title mr-2"}>{location.name}</p>
              <p className={"separator"}>|</p>
              <h1 className={"title-block m-0 mx-1"}>
                {titleComponents.map((comp) => location[comp.locationKey]).join(", ")}
              </h1>
            </div>
          </div>
          <div className="row d-flex flex-column mt-4">
            {isLoading ? (
              <div>
                <div className="spinner-border text-primary mt-4 ml-4" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : null}
            {!isLoading && data ? (
              <>
                <article className="article">
                  <header className="gap-1">
                    <h2>
                      Brand Standards Report: <strong>{reportSubmitDate}</strong>
                    </h2>
                    <p>
                      Submitted: <strong>{reportSubmitTime}</strong>
                    </p>
                    <p>
                      Total Photos: <strong>{data?.photos_count}</strong>
                    </p>
                    <p>
                      Completed By: <strong>{data?.report_submit_by?.full_name}</strong>
                    </p>
                  </header>
                  <section className="mt-4">
                    <p>Brand Standard Notes:</p>
                    <div className="mt-2">
                      <textarea
                        className="form-control"
                        rows={3}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        contentEditable={false}
                        placeholder="Enter your note"
                        disabled
                      />
                    </div>
                  </section>
                  <PhotoProvider>
                    <section className="mt-4" hidden={brand_standard_reports?.length === 0}>
                      {brand_standard_reports.map((brand_standard_report, index) => (
                        <div key={brand_standard_report.id} className="mt-4">
                          <p>
                            <strong>{brand_standard_report.brand_standard?.name}</strong>
                          </p>
                          <div className="row">
                            {brand_standard_report.attachments.map((photo) => {
                              const photo_url = photo.file?.url;
                              return (
                                <div key={photo.id} className="col-4 mb-3">
                                  <PhotoView src={photo_url}>
                                    <img
                                      src={photo_url}
                                      alt={photo_url}
                                      className="img-fluid"
                                      style={{ cursor: "pointer", maxWidth: 200 }}
                                    />
                                  </PhotoView>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </section>
                  </PhotoProvider>
                </article>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
