import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SnapShotHeader from "../../../components/SnapShotHeader";
import { BASE_URL_ENV } from "../SnapshotFacilityTasks/constants";
import styles from "./styles.module.css";
import axios from "axios";
import { useSnapShotUser } from "../../../utilities/helper";
import moment from "moment";
import { BreadCrumb } from "../../../components";
import { PATH } from "../../../routers";
import { getLocationList } from "../../../utilities/common";

const TABLE_FIELDS = [
  {
    header: "Date Time",
    key: "report_submit_at",
  },
  {
    header: "Photos",
    key: "photos_count",
  },
  {
    header: "Notes",
    key: "notes",
  },
  {
    header: "Completed",
    key: "completed",
  },
  {
    header: "User",
    key: "report_submit_by",
  },
];

export default function SnapshotArchiveBrandStandards(props) {
  const {
    match: {
      params: { site_code },
    },
  } = props;

  const locationList = getLocationList();
  const location = locationList.find((location) => {
    return location.location_code === site_code;
  });
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [currentSort, setCurrentSort] = useState({ facility_name: "sort-up" });
  const { user } = useSnapShotUser();
  // const portfolioId = user.portfolio?.id;

  const fetchSnapshotDashboard = async () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          "X-User-Email": user.email,
          "X-User-Token": user.user_token,
        },
      };
      const response = await axios.get(
        `${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/brands_reports/facility_brands_reports/${site_code}`,
        config
      );
      setDataList(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSnapshotDashboard();
  }, []);

  const toggleSort = (key) => {
    let currSort = currentSort[key] || "sort";
    if (currSort === "sort") {
      currSort = "sort-up";
    } else if (currSort === "sort-up") {
      currSort = "sort-down";
    } else if (currSort === "sort-down") {
      currSort = "sort-up";
    }
    sortDataList(key, currSort);
    setCurrentSort({ [key]: currSort });
  };

  const sortDataList = (key, sortDirection) => {
    const newDataList = [...dataList];
    newDataList.sort((t1, t2) => {
      const sortD = sortDirection === "sort-up" ? 1 : -1;

      switch (key) {
        case "report_submit_at":
          return (moment(t1[key] || 0).unix() - moment(t2[key] || 0).unix()) * sortD;
        case "photos_count":
          return (parseInt(t1[key]) - parseInt(t2[key])) * sortD;
        case "notes":
          return t1[key] > t2[key] ? sortD : -sortD;
        case "completed":
          return t1[key] > t2[key] ? sortD : -sortD;
        case "report_submit_by":
          return t1[key]?.full_name > t2[key]?.full_name ? sortD : -sortD;
        default:
          return 0;
      }
    });
    setDataList(newDataList);
  };

  return (
    <div>
      <Helmet>
        <title>Archive Brand Standards</title>
      </Helmet>
      <SnapShotHeader />
      <div className="bg-light">
        <div className="row justify-content-between pt-2">
          <div className="col-9 ml-2">
            <BreadCrumb title="Dashboard" path={PATH.DASHBOARD} />
          </div>
        </div>
      </div>
      <div className="col-12 bg-light min-vh-100">
        <div className="col-11 mx-auto py-3">
          <div className="d-flex align-items-center justify-content-between">
            <p>
              <span className={[styles.topHeader]}>Brand Standards</span>
              <span className={["title-block m-0 mx-1", styles.blackText]}> | {location?.name}</span>
            </p>
          </div>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                {TABLE_FIELDS.map((field) => (
                  <th
                    key={field.key}
                    scope="col"
                    onClick={() => toggleSort(field.key)}
                    style={{ cursor: "pointer", userSelect: "none" }}
                  >
                    {field.header} &nbsp;
                    <i className={`fa-solid fa-${currentSort[field.key] || "sort"}`}></i>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <div className="text-center">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null}
              {!isLoading && dataList.length !== 0
                ? dataList.map((item) => (
                    <tr key={item.id}>
                      <th scope="row" style={{ minWidth: 210 }}>
                        <Link to={`/snapshot/${site_code}/brands-report/${item?.id}`}>
                          {item.report_submit_at ? moment(item.report_submit_at).format("MM/DD/YYYY LT") : ""}
                        </Link>
                      </th>
                      <td className="text-right">{item.photos_count}</td>
                      <td className="text-left">{item.notes}</td>
                      <td className="text-right">{item.completed ? "Yes" : "No"}</td>
                      <td className="text-right flex items-center">{item.report_submit_by?.full_name || "N/A"}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
