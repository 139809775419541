import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { setLocationList } from "../../utilities/common";
import { Helmet } from "react-helmet";
import SnapShotHeader from "../../components/SnapShotHeader";
import { SUB_DOMAIN } from "../../routers";
import { getFacilityTitle, useSnapShotUser } from "../../utilities/helper"

const SnapShotPage = ({ history }) => {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const { user } = useSnapShotUser();

  useEffect(() => {
    axios.post("/api/rafa/locations", {
      email: user.email,
      token: user.user_token
    }).then((result) => {
      const activeLocations = result.data.filter(e => !e.hide_in_snapshot);

      setLocationList(activeLocations);
      setData(activeLocations);
    });
  }, []);

  const renderSearchResult = () => {
    const searchResults = data.filter((item) => {
      const { location_code = "", state, city, address, zip, name } = item;
      const lowerCaseQuery = query.toLowerCase();
      return (
        location_code.toLowerCase().includes(lowerCaseQuery) ||
        name.toLowerCase().includes(lowerCaseQuery) ||
        state.toLowerCase().includes(lowerCaseQuery) ||
        city.toLowerCase().includes(lowerCaseQuery) ||
        address.toLowerCase().includes(lowerCaseQuery) ||
        zip.toLowerCase().includes(lowerCaseQuery)
      );
    });

    return searchResults.map((result) => {
      const href = `snapshot/${result.location_code}`;
      const facilityTitle = getFacilityTitle(result.name, result.store_number)
      return (
        <p key={result.id} className={styles.item}>
          <a href={href} className="my-2">
            {facilityTitle} | {result.address}, {result.city}, {result.state} {result.zip}
          </a>
        </p>
      );
    });
  };

  const viewTask = () => {
    history.push("/tasks-overview");
  };

  const renderViewTasksButton = () => {
    const subDomain = window.location.host.split(".")[0];
    const routerKey = Object.keys(SUB_DOMAIN).find((value) => subDomain === value);
    if (["snapshot", "staging-snapshot"].includes(routerKey)) {
      return (
        <button className="btn btn-primary view-task-btn" onClick={viewTask}>
          View Tasks
        </button>
      );
    }
    return null;
  };

  return (
    <div>
      <Helmet>
        <title>Snapshot</title>
      </Helmet>
      <SnapShotHeader />

      <div className="col-12 bg-light min-vh-100">
        <div className="col-10 mx-auto py-3">
          <div className="d-flex align-items-center justify-content-between">
            <p className={styles.topHeader}>Facility Search</p>
            {renderViewTasksButton()}
          </div>
          <div className="form-group position-relative search-input">
            <FaSearch className=" position-absolute" />
            <div>
              <input
                onChange={(e) => setQuery(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Search by Site Code or Address..."
              />
            </div>
            <div>{renderSearchResult()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnapShotPage;
