import Axios from "axios";
import { KEY_SNAPSHOT_USER } from "../../utilities/constants";
import { getBaseUrl } from "./SnapshotFacilityTasks/constants";

const getAxiosWithDefaultHeaders = () => {
  const user = JSON.parse(localStorage.getItem(KEY_SNAPSHOT_USER));
  const baseURL = getBaseUrl()
  return Axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      'SnapshotUsername': user?.full_name,
      'X-User-Token': user.user_token,
      'X-User-Email': user.email,
    }
  });
};

export default getAxiosWithDefaultHeaders;
