import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from "./Button";

function Header({ setUser }) {
  const [publishable, setPublishable] = useState(true);
  const [restorable, setRestorable] = useState(true);
  const [maintenanceSetting, setMaintenanceSetting] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setIsProcessing(true);
    axios
      .get(`/api/settings?name=maintenance`)
      .then((res) => {
        if (res.status === 200) {
          setMaintenanceSetting(res.data);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    setUser(null);
  };

  const handlePublish = () => {
    if (!publishable) {
      return;
    }

    setPublishable(false);
    setTimeout(() => setPublishable(true), 60 * 1000); // Wait for 1 minute

    axios.post(`/api/publish_contents`).then((res) => {
      if (res.status === 200) {
        alert("Done, content updates will be published in a few minutes!");
      } else {
        alert("Failed to publish contents.");
      }
    });
  };

  const handleToggleMaintenanceMode = () => {
    setIsProcessing(true);

    if (maintenanceSetting?.value === "true") {
      axios.put(`/api/settings/${maintenanceSetting.id}`, { value: "false" }).then((res) => {
        if (res.status === 200) {
          setMaintenanceSetting({ ...maintenanceSetting, value: "false" });
          alert("Done, maintenance mode is off");
        } else {
          alert("Failed to trigger maintenance mode off");
        }
      });
    } else {
      if (!maintenanceSetting) {
        axios.post(`/api/settings`, { name: "maintenance", value: "true", description: "" }).then((res) => {
          if (res.status === 200) {
            setMaintenanceSetting({ name: "maintenance", value: "true", description: "" });
            alert("Done, maintenance mode is on");
          } else {
            alert("Failed to trigger maintenance mode on");
          }
        });
        setIsProcessing(false);
        return;
      }
      axios.put(`/api/settings/${maintenanceSetting.id}`, { value: "true" }).then((res) => {
        if (res.status === 200) {
          setMaintenanceSetting({ ...maintenanceSetting, value: "true" });
          alert("Done, maintenance mode is on");
        } else {
          alert("Failed to trigger maintenance mode on");
        }
      });
    }
    setIsProcessing(false);
  };

  return (
    <div className="col-12 my-2 d-flex justify-content-end border-bottom pb-2">
      <Link className="mx-4 btn btn-success float-right" title="Back To Main Page" to={`/`}>
        Back To Main Page
      </Link>
      <Button
        title={
          isProcessing
            ? "Loading..."
            : maintenanceSetting?.value === "true"
            ? "SET MAINTENANCE MODE OFF"
            : " SET MAINTENANCE MODE ON"
        }
        className="mr-3"
        onPress={handleToggleMaintenanceMode}
        disabled={isProcessing}
        typeBtn="warning"
      />
      <Button
        title="Rebuild CMS Pages"
        onPress={handlePublish}
        className="mr-3"
        disabled={!publishable}
        typeBtn={publishable ? "danger" : "dark"}
        tips={publishable ? "Rebuild now" : "Please wait for a few minutes until build finishes"}
      />
      <Button title="Logout" onPress={handleLogout} typeBtn="dark" />
    </div>
  );
}

export default Header;
