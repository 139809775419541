import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import _ from "underscore";
import { DropDown, Input } from "../../components";
import moment from "moment";

const REASON_DATA = [
  "Evidence or suspicion of using or possessing narcotics/drug paraphernalia",
  "Evidence or suspicion of sleeping, living, or loitering on facility grounds",
  "Using threatening or aggressive behavior towards tenants, themselves, or SpareBox Storage staff",
  "Evidence or suspicion of damage created by tenant to the facility structure or to the integrity of buildings",
  "Tenant ignores or deliberately does not comply with facility rules",
  "Evidence or suspicion of breaking into units",
  "Tenant is a risk to themselves and others",
];

function LeaseTerminations(props) {
  const [facilities, setFaclities] = useState([]);
  const [units, setUnits] = useState([]);
  // Form Data
  const [facility, setFacility] = useState("");
  const [selectedUnits, setSelectedUnits] = useState([]);

  const NON_RENEWAL = "Non-renewal",
    TERMINATION = "Termination";
  const [terminationType, setTerminationType] = useState(NON_RENEWAL);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [reason, setReason] = useState("");
  const [notes, setNotes] = useState("");

  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    setUnits([]);
    fetchUnits(facility);
  }, [facility]);

  const fetchLocations = () => {
    axios.get("/api/locations").then((res) => {
      const locations = (res.data || []).map((e) => ({
        title: e.name,
        value: e.location_code,
      }));
      setFaclities(locations);
    });
  };

  const fetchUnits = (locationCode) => {
    if (!locationCode || locationCode === "") return;

    const data = {
      service_name: "GetAllUnits",
      xml_body: {
        strLocationCode: locationCode,
      },
    };

    axios.post("/api/ssm", data).then((res) => {
      const locationUnits = (res.data.SSM.Record || []).map((e) => ({
        label: e.UNITEXTERNALID[0],
        value: e.UNITEXTERNALID[0],
      }));
      setUnits(locationUnits);
    });
  };

  const fetchUnitTenant = (locationCode, unitNumber) => {
    const data = {
      service_name: "GetUnitsList",
      xml_body: {
        strLocationCode: locationCode,
        strSearchType: "4",
        strSearchString: unitNumber,
        strSortType: "",
      },
    };
    return axios.post("/api/ssm", data).then((res) => {
      if (res.data.UnitsList?.Unit?.length > 0 && res.data.UnitsList?.Unit[0].UnitStatus[0] === "Rented") {
        return {
          unitNumber: unitNumber,
          tenantId: res.data.UnitsList?.Unit[0].TenantId[0],
        };
      } else {
        return null;
      }
    });
  };

  const sendTerminationNotice = (tenantId, tenantUnits, apiDelay) => {
    const unitNumbers = tenantUnits.map((e) => ({ title: e.unitNumber }));

    const reasonAndNotes = [reason, notes.trim()].filter((e) => !!e).join(" - ");

    const timestamp = moment(date + " " + time);

    const data = {
      type: terminationType,
      created_at: moment().format("l"),
      created_at_date: moment().format("l"),
      form_date: timestamp.format("l"),
      time: timestamp.format("LT"),
      unit_numbers: unitNumbers,
      tenantId: tenantId,
      locationCode: facility,
      termination_type: terminationType.toUpperCase(),
      reason_and_notes: reasonAndNotes,
    };

    return axios.post("/api/notices/lease_termination_notice", data).catch((e) => {
      setMessage("API failed and something went wrong!");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const tenantData = await Promise.all(selectedUnits.map((e) => fetchUnitTenant(facility, e.value))).then(
      (result) => {
        return _.groupBy(
          result.filter((e) => !!e),
          (e) => e.tenantId
        );
      }
    );

    const tenantIds = Object.keys(tenantData);

    tenantIds.forEach((tenantId, idx) => {
      setTimeout(async () => {
        await sendTerminationNotice(tenantId, tenantData[tenantId]);
      }, 5000 * idx);
    });

    setMessage("Done. Notices will be sent out shortly!");
  };

  const reasonList = REASON_DATA.map((e) => ({ title: "Breach of Lease: " + e, value: e }));

  const validForm = !!facility && !!selectedUnits.length && !!terminationType && !!date && !!time && !!reason;

  return (
    <>
      <Link
        className="mx-4 btn btn-dark float-right"
        title="Lease Terminations History"
        to="/lease-terminations/history"
      >
        Lease Terminations History
      </Link>
      <div className="container my-4">
        <form className="p-2" onSubmit={handleSubmit}>
          <h1 className="text-center mb-5">Generate Lease Termination</h1>
          <DropDown
            title="Facility"
            options={facilities}
            value={facility}
            onChange={(e) => setFacility(e.target.value)}
            includeBlank="Select..."
            required
          />
          <div className="row py-2">
            <div className="col-4">Unit(s)</div>
            <div className="col-8 px-0">
              <Select
                name="units"
                value={selectedUnits}
                isMulti
                className="w-full"
                onChange={(items) => {
                  setSelectedUnits(items);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: "46px",
                  }),
                }}
                isDisabled={units.length === 0}
                options={units}
              />
            </div>
          </div>
          <div className="row py-2">
            <div className="col-4">Type</div>
            <div className="col-3">
              <input
                type="radio"
                required
                className="mr-2"
                id="type_non_renewal"
                name="termination_type"
                value={NON_RENEWAL}
                onChange={(e) => setTerminationType(e.target.value)}
              />
              <label htmlFor="type_non_renewal">Non-renewal</label>
            </div>
            <div className="col-3">
              <input
                type="radio"
                required
                className="mr-2"
                id="type_termination"
                name="termination_type"
                value={TERMINATION}
                onChange={(e) => setTerminationType(e.target.value)}
              />
              <label htmlFor="type_termination">Termination</label>
            </div>
          </div>
          <Input title="Date" type="date" onChange={(e) => setDate(e.target.value)} required />
          <Input title="Time" type="time" onChange={(e) => setTime(e.target.value)} required />
          <DropDown
            title="Reason"
            options={reasonList}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            includeBlank="Select..."
            required
          />
          <div className="row py-2">
            <div className="col-4">Supplementary Notes</div>
            <textarea
              className="col-8 py-2"
              name="supplementary_notes"
              rows={6}
              placeholder="This will appear in the official notice. Please be specific, concise, and use proper grammar."
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>

          <div className="row mt-4">
            <div className="col-4"></div>
            <div className="col-8 text-center ">
              <input type="submit" disabled={!validForm} className="btn btn-success" value="Send Termination" />
              <p className="text-center text-bold mt-4">{message}</p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default LeaseTerminations;
