import React, { useEffect, useState } from "react";
import { getRandomIntWithoutSequential } from "../utilities/common";
import Modal from "./Modal";
import { IoIosLogOut } from "react-icons/io";
import { localStorageClear, useSnapShotUser } from "../utilities/helper";

const SnapShotHeader = () => {
  const [generateGateCodeVisibleModal, setGenerateGateCodeVisibleModal] = useState(false);
  const [generateGateCode, setGenerateGateCode] = useState("");
  const { setUser, user } = useSnapShotUser();

  const handleLogout = () => {
    sessionStorage.clear();
    setUser(null);
    localStorageClear()
    window.location.href = "/login";
  };


  useEffect(() => {
    if (generateGateCodeVisibleModal) {
      handleGenerateGateCode();
    }
  }, [generateGateCodeVisibleModal]);

  const handleGenerateGateCode = () => {
    const gateCode = getRandomIntWithoutSequential();
    setGenerateGateCode(gateCode);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark py-3 mx-3 bg-white" id="header">
      <div className="container-fluid">
        <div className="navbar-brand">
          <a href="/">
            <img src="/Snapshot_Logo.svg" alt="logo" style={{ width: "8.5rem", cursor: "pointer" }} />
          </a>
        </div>
        <div>
          <a className="btn btn-indigo mr-2 text-primary" href="/dashboard">
            <img src="/dashboard_icon.svg" alt="logo" className="mr-1" style={{ width: "24px" }} />
            Dashboard
          </a>
          <button className="btn btn-dark" onClick={handleLogout}>
            Logout
            <IoIosLogOut className="ml-2" size={24} />
          </button>
        </div>
      </div>
      <Modal
        visible={generateGateCodeVisibleModal}
        setVisible={() => {
          setGenerateGateCodeVisibleModal(false);
          setGenerateGateCode("");
        }}
        title="Generated Gate Code"
      >
        <input readOnly className="form-control" value={generateGateCode} />
        <button className="btn btn-success my-2" onClick={handleGenerateGateCode}>
          Generate new code
        </button>
      </Modal>
    </nav>
  );
};

export default SnapShotHeader;
