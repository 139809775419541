import React from "react"

const RateChangesLog = ({ changes, onClickProcess, onClickDiscard }) => {
  return (
    <div className="flex-column d-flex align-items-center my-5">
      <h4>
        Pending Rate Changes
      </h4>
      <div className="my-2 d-flex col-md-6">
        <div className="col-4 d-flex flex-column text-center">
          <h6>Received</h6>
          <h6>{changes.units || '-'}</h6>
        </div>
        <div className="col-4 d-flex flex-column text-center">
          <h6>Pending</h6>
          <h6>{changes.units - changes.errors || '-'}</h6>
        </div>
        <div className="col-4 d-flex flex-column text-center">
          <h6>Errors</h6>
          <h6>{changes.errors || '-'} </h6>
        </div>
      </div>
      <div className="my-2 d-flex justify-content-between col-md-3">
        <button
          type="button"
          className="btn btn-success "
          onClick={onClickProcess}
        >
          PROCESS
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={onClickDiscard}
        >
          DISCARD
        </button>
      </div>
    </div>
  )
}

export default RateChangesLog;