export const KEY_TOKEN = "authToken";
export const KEY_USER = "sparebox_user";
export const KEY_SNAPSHOT_USER = "sparebox_snapshot_user";

export const API_ENDPOINT = {
  DELINQUENT_TENANTS: "api/delinquent-tenants",
  USERS: "api/users",
  ROLES: "api/roles",
  SNAPSHOT: "/api/snapshot_locations",
};

export const SNAPSHOT_DETAIL_KEYS = {
  FACILITY_ALERTS: "facility_alerts",
  // TITLE
  FACILITY_ADDRESS: "facility_address",
  FACILITY_CITY: "facility_city",
  FACILITY_STATE: "facility_state",
  FACILITY_ZIP: "facility_zip",
  // LOCATION INFORMATION
  FACILITY_NICKNAME: "facility_nickname",
  FACILITY_PRONUNCIATION: "facility_pronunciation",
  FACILITY_UNIT_COUNT: "facility_unit_count",
  FACILITY_PHONE: "facility_phone",
  FACILITY_TIMEZONE: "facility_timezone",
  FACILITY_LANDMARK: "facility_landmark",
  // PROPERTY INFORMATION
  FACILITY_DATE_OF_ACQUISITION: "facility_date_of_acquisition",
  FACILITY_PREVIOUS_OWNER: "facility_previous_owner",
  FACILITY_KIOSK: "facility_kiosk",
  FACILITY_GATE: "facility_gate",
  FACILITY_FENCE: "facility_fence",
  FACILITY_CAMERAS: "facility_cameras",
  FACILITY_TRUCK_ACCESS: "facility_truck_access",
  FACILITY_FLOOR_COUNT: "facility_floor_count",
  FACILITY_ELEVATOR_TYPE: "facility_elevator_type",
  INTERIOR_UNIT_ACCESS_CODE: "interior_unit_access_code",
  LOCAL_TOW_COMPANY: "local_tow_company",
  TOW_COMPANY_PHONE: "tow_company_phone",
  // GATE ACCESS
  FACILITY_ACCESS_HOURS: "facility_access_hours",
  FACILITY_EMERGENCY_CODE: "facility_emergency_code",
  FACILITY_TEMP_CODE: "facility_temp_code",
  FACILITY_AUCTION_WINNER: "facility_auction_winner",
  // UNIT AMENITIES
  FACILITY_UNIT_HEIGHT: "facility_unit_height",
  CLIMATE_CONTROLLED_UNITS: "climate_controlled_units",
  CLIMATE_CONTROLLED_TYPE: "climate_controlled_type",
  CLIMATE_CONTROLLED_RANGE: "climate_controlled_range",
  FACILITY_RV_RANKING: "facility_rv_ranking",
  FACILITY_INDOOR_PARKING: "facility_indoor_parking",
  FACILITY_OUTDOOR_PARKING: "facility_outdoor_parking",
  FACILITY_OUTSIDE_UNITS: "facility_outside_units",
  FACILITY_PARKING_SURFACE: "facility_parking_surface",
  RV_AND_BOAT_ONLY: "rv_and_boat_only",
  // UNIQUE DETAILS
  FACILITY_UNIQUE_DETAILS: "facility_unique_details",
  // BILLING AND INSURANCE
  FACILITY_INSURANCE: "facility_insurance",
  FACILITY_PROTECTION: "facility_protection",
  FACILITY_INSURANCE_LEVELS: "facility_insurance_levels",
  FACILITY_UNIT_TAX: "facility_unit_tax",
  FACILITY_PARKING_TAX: "facility_parking_tax",
  FACILITY_UNIT_TAX_RATE: "facility_unit_tax_rate",
  FACILITY_PARKING_TAX_RATE: "facility_parking_tax_rate",
  FACILITY_LATE_FEE_SCHEME: "facility_late_fee_scheme",
  FACILITY_LIEN_FEE: "facility_lien_fee",
  // file
  PROPERTY_MAP: "property_map",
  PROPERTY_MAP_SIGNED_URL: "property_map_signed_url",
};

export const PAGES_UNDER_ROLE = [
  {
    name: "Location Settings",
    path: "/locations",
  },
  {
    name: "State Settings",
    path: "/states",
  },
  {
    name: "City Settings",
    path: "/cities",
  },
  {
    name: "FAQ",
    path: "/faq",
  },
  {
    name: "Auctions",
    path: "/auctions",
  },
  {
    name: "Settings",
    path: "/settings",
  },
  {
    name: "File Management",
    path: "/file-management",
  },
  {
    name: "Blog",
    path: "/blogs",
  },
  {
    name: "Users",
    path: "/users",
  },
  {
    name: "Snapshot",
    path: "/snapshot",
  },
  {
    name: "Website Pages",
    path: "/pages",
  },
  {
    name: "Street Rate Upload",
    path: "/street-rate-upload",
  },
  {
    name: "Tenant Rate Upload",
    path: "/tenant-rate-upload",
  },
  {
    name: "Lease Terminations",
    path: "/lease-terminations",
  },
  {
    name: "Communication Templates",
    path: "/communication-templates",
  },
  {
    name: "Communication History",
    path: "/communication-history",
  },
  {
    name: "Marketing Features",
    path: "/marketing-features",
  },
  {
    name: "Gate Code Rotation",
    path: "/gate-code-rotation",
  },
];
